import React from 'react';
import {
    HashRouter,
    Routes,
    Route
  } from 'react-router-dom';
// import Home from '../Components/home';
import Pos from '../Components/pos';
// import Header from './header';
// import Footer from './footer';
import Auth from '../Components/auth';
import BusinessBox from '../Components/businessBox';
import BbHistory from '../Components/bbHistory';
import Task from '../Components/Assignments/task';
import Appointment from '../Components/Appointments/appointment';
import RecipeView from '../Components/Recipes/views/recipeView';
import RecipesView from '../Components/Recipes/views/recipesView';
import ItemMaster from '../Views/itemMaster';
import TakeOutProduction from '../Views/takeOutProduction';
import OrderDispatch from '../Views/orderDispatch';
import OrderReady from '../Views/orderReady';
import Container from './NiceAdmin/container';
const Content = () => {
    return (
        <>
        {/* <Header /> */}
            <HashRouter>
                <Routes>            
                    {/* MAIN PATH */}
                    <Route exact path="/" element={<Pos />} />

                    {/* POS path */}
                    <Route exact path="/pos" element={<Pos />} />
                    <Route exact path="/businessbox" element={<BusinessBox />} />
                    <Route exact path="/bb/history" element={<BbHistory />} />
                    <Route exact path="/bb/history/:id" element={<BbHistory />} />

                    {/* Assignments tasks */}
                    <Route exact path="/assignments" element={<Task />} />

                    {/* Appointments */}
                    <Route exact path="/appointments" element={<Appointment />} />

                    {/* Recipes */}
                    <Route exact path="/recipes" element={<RecipesView />} />
                    <Route exact path="/recipes/:productIdMd5" element={<RecipeView />} />

                    {/* Item Master */}
                    <Route exact path="/itemmaster" element={<ItemMaster />} />

                    {/* Take Out Production */}
                    <Route exact path="/takeoutproduction" element={<TakeOutProduction />} />

                    <Route exact path="/orderdispatch" element={<OrderDispatch />} />

                    <Route exact path="/orderready" element={<OrderReady />} />

                    <Route exact path="/nadmin-:page" element={<Container />} />
                    
                    {/* Logger path */}
                    <Route exact path="/auto/auth/by/session/key/:path/:session/:token" element={<Auth />} />
                    <Route exact path="/auto/auth/by/session/key/:path/:session/:token/:jwt" element={<Auth />} />
                </Routes>
            </HashRouter>
        {/* <Footer /> */}
        </>
    )
}
export default Content;