
export const Constants = {
    "REGISTERED": "REGISTRADO",
    "TO_SEND": "LISTO PARA SER ENVIANDO",
    "FAILED": "FALLÓ EL ENVÍO",
    "WAITING_RESPONSE": "ESPERA DE RESPUESTA DGII",
    "TO_NOTIFY": "ESPERA NOTIFICACIÓN DGII",
    "FINISHED": "FINALIZADO",

    /*-------LEGAL STATUS-------*/
    "REJECTED": "RECHAZADO",
    "ACCEPTED": "ACEPTADO",
    "ACCEPTED_WITH_OBSERVATIONS": "ACEPTADO CON OBSERVACIONES",
    "IN_PROCESS": "EN PROCESO",
}