import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import Select from 'react-select';
import { Form, Button } from "react-bootstrap";
import DateTimePicker from 'react-datetime-picker';
import SelectGroup from "../../../Components/atoms/SelectGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faTimes } from "@fortawesome/free-solid-svg-icons";
import Multiselect from "multiselect-react-dropdown";
import PropTypes from "prop-types";
import { getDateFormatted } from "../../../Vendor/common";
import LoadingOverlay from "../../../Layouts/NiceAdmin/LoadingOverlay";
const defaultParameters = {
    customerId: 0,
    idLeadSteps: 1, /** Oportunidad de venta */
    currencyId: 0,
    workId: "",
    deliveryDate: "",
    supplierDate: "",
    leadStatus: 2, /** No Iniciado */
    timesOrder: [],
    details: []
}
const LeadSale = () => {
    const [bkParameters,setBkParameters] = useState(defaultParameters);
    const [customers,setCustomers] = useState([])    
    const [customerSelected,setCustomerSelected] = useState(null)
    const [currencies,setCurrencies] = useState([])
    const [currencySelected,setCurrencySelected] = useState(null)
    const [workFlows,setWorkFlows] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const [deliveryDate,setDeliveryDate] = useState(new Date())
    const [supplierOrderDate,setSupplierOrderDate] = useState(new Date())    
    const [subCategories,setSubCategories] = useState([])
    const [subCategory,setSubCategory] = useState(0)
    const [itemList,setItemList] = useState([])
    const [itemSelected,setItemSelected] = useState(null)
    const [units,setUnits] = useState([])
    const [unitSelected,setUnitSelected] = useState(null)
    const [taxes,setTaxes] = useState([])
    const [totalWorkFlowDays, setTotalWorkFlowDays] = useState(0)
    const customerReactSelect = useRef()
    const currencyReactSelect = useRef()
    const itemReactSelect = useRef()
    const unitReactSelect = useRef()
    const multiSelectReactElement = useRef()
    const localStorageWT = localStorage.getItem('OSHEN-LOC-STR-WT');
    /** ------ item parameter selected ------ */
    const [itemQuantitySelected,setItemQuantitySelected] = useState(0)
    const [itemPriceSelected,setItemPriceSelected] = useState(0)
    const [itemDiscountSelected,setItemDiscountSelected] = useState(0)
    const [itemTaxesSelected,setItemTaxesSelected] = useState([])
    
    const searchInitData = async() => {
        setIsLoading(true);
        try{
            const resource = await axios.get(`${process.env.REACT_APP_NV_API_PATH}/v1.0/lead/miscellaneous/lst`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorageWT}`
                    }
                }
            ),
            dataSource = resource.data;
            setCustomers(dataSource.customers)
            setCurrencies(dataSource.currencies)
            setWorkFlows(dataSource.workFlows)
            setSubCategories(dataSource.subCategories)
            setUnits(dataSource.units)
            setCustomerSelected(null)
            setTaxes(dataSource.taxes)
            setIsLoading(false)
        } catch( error ) {
            console.log('err', error);
            if ( error.response !== undefined && error.response.status == 401 )
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Unauthorized',
                    text: "Token invalido"
                }).then(()=>{
                    location.href = process.env.REACT_APP_PATH_APP;
                });
                return;
            }
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const getMaterialsBySubCategory = async ( idSubCategory ) => {
        // setLoadings({
        //     ...loadings,
        //     chargingProducts: true
        // });
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.get(process.env.REACT_APP_PATH_API + 'get/items/by/subcategory/'+idSubCategory),
                data =  await resource.data;
                setItemList(data.list);
                setSubCategory(idSubCategory);
                setItemSelected(null);
                // setUnitConversions(data.unitConversions);
                // setSubCategoryItem(idSubCategory);
                // setLoadings({
                //     ...loadings,
                //     chargingProducts: false
                // });
        } catch(error){
            // setLoadings({
            //     ...loadings,
            //     chargingProducts: false
            // });
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const valid = (parameters) => {        

        if ( parameters.workId.length < 1 )
            return { message: 'Debe ingresar el numero de la obra', status: false }

        if ( parameters.timesOrder.length < 1 )
            return { message: 'No se ha registrado el tiempo de la orden', status: false }

        if ( parameters.deliveryDate.length < 1 )
            return { message: 'Debe ingresar la fecha de envío', status: false }

        if ( parameters.supplierDate.length < 1 )
            return { message: 'Debe ingresar la fecha del proveedor', status: false }

        if ( parameters.details.length < 1 )
            return { message: 'Debe agregar al menos un artículo', status: false }

        return {
            message: '',
            status: true
        }
    }
    const clearItemRow = () => {
        setSubCategory(0)
        setItemSelected(null)
        setUnitSelected(null)
        setItemQuantitySelected(0)
        setItemPriceSelected(0)
        setItemDiscountSelected(0)
        setItemTaxesSelected([])
        multiSelectReactElement.current.resetSelectedValues()
    }
    const validRowToAdd = () => {
        if ( itemSelected === null )
        {
            return {
                message: "Debe seleccionar al menos un artículo",
                code: 400
            }
        }
        if ( itemQuantitySelected.length < 1 )
        {
            return {
                message: "Debe ingresar un numero en el campo de cantidad",
                code: 400
            }
        }
        if ( unitSelected === null )
        {
            return {
                message: "Debe seleccionar al menos una unidad",
                code: 400
            }
        }        
        if ( itemPriceSelected.length < 1 )
        {
            return {
                message: "Debe ingresar un numero en el campo de precio",
                code: 400
            }
        }        
        if ( itemDiscountSelected.length < 1 )
        {
            return {
                message: "Debe ingresar un numero en el campo de descuento",
                code: 400
            }
        }
        return {
            message: "",
            code: 200
        }
    }
    const addItemRow = () => {
        const vr = validRowToAdd();
        if ( vr.code === 400 ) {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: vr.message
            });
            return
        }
        let localItemRow = bkParameters.details !== undefined && bkParameters.details !== null ? [...bkParameters.details] : []
        const subTotal = parseFloat(itemPriceSelected)*parseFloat(itemQuantitySelected);
        const discountAmount = ((parseFloat(itemPriceSelected)*(parseFloat(itemDiscountSelected)/100))*parseFloat(itemQuantitySelected));
        const subTotalRow = subTotal - discountAmount;
        /** ----- Taxes ----- */
        let totalTaxes = 0;
        let arrTaxes = [];
        if ( itemTaxesSelected )
            for ( let counter = 0; counter < itemTaxesSelected.length; counter++ ) {
                const calcTax = parseFloat( subTotalRow * (itemTaxesSelected[counter].percent/100) );
                totalTaxes += calcTax;
                arrTaxes.push({
                    idTax: itemTaxesSelected[counter].cat,
                    taxPercent: itemTaxesSelected[counter].percent,
                    taxAmount: calcTax
                })
            }

        const total = subTotalRow + totalTaxes;
        localItemRow.push({
            productId: parseInt(itemSelected.value),
            item: itemSelected.label,
            qty: parseFloat(itemQuantitySelected),
            unitId: parseInt(unitSelected.value),
            unit: unitSelected.label,
            productPrice: parseFloat(itemPriceSelected),
            discountPercent: parseFloat(itemDiscountSelected),
            discountAmount: parseFloat(parseFloat(discountAmount).toFixed(2)),
            subTotal: subTotal,
            total: total,
            taxAmount: parseFloat(totalTaxes.toFixed(2)),
            taxes: arrTaxes
        })
        setBkParameters({
            ...bkParameters,
            details: localItemRow
        })
        clearItemRow()
    }
    const createLead = async() => { 
        
        if ( customerSelected === null )
        {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debe seleccionar al menos un cliente'
            });
            return;
        }

        if ( currencySelected === null )
        {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debe seleccionar al menos una moneda'
            });
            return;
        }
        
        const parameters = {
            ...bkParameters,
            customerId: parseInt(customerSelected.value),
            currencyId: parseInt(currencySelected.value),
            deliveryDate: getDateFormatted(deliveryDate),
            supplierDate: getDateFormatted(supplierOrderDate)
        }

        const validRes = valid(parameters);

        if ( !validRes.status ) {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: validRes.message
            });
            return;
        }

        try{
            setIsLoading(true);
            const resource = await axios.post(`${process.env.REACT_APP_NV_API_PATH}/v1.0/lead`,
                parameters,
                {
                    headers: {
                        Authorization: `Bearer ${localStorageWT}`
                    }
                }
            ),
            data = resource.data;
            if ( data.code === 401 )
                location.reload()

            setIsLoading(false);
            Swal.fire({
                icon: "success",
                title: "Transacción Exitosa",
                text: data.message
            }).then(()=>location.reload())
        } catch ( err ) {
            setIsLoading(false);
            console.log( '::::ERR::::', err.response);
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: err.response.data.message
            })
        }

    }
    useEffect(searchInitData,[]);
    return (
    <>
        <div className="row">

            <div className="col-lg-12">

                <div className="card">

                    <div className="card-body">
                        
                        <div className="row mb-3">

                            <div className="col">

                                <div className="row">
                                    <div className="col">
                                        <h2 style={{marginTop: 10}}>Datos Generales</h2>
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="container-fluid">

                                        <div className="row mb-3">
                                            <div className="col">
                                                <label>Cliente {isLoading?'Cargando':''}</label>
                                                <Select 
                                                    options={customers}                         
                                                    className="form-control select-styling" 
                                                    value={customerSelected}
                                                    defaultValue={customerSelected} 
                                                    onChange={setCustomerSelected}
                                                    ref={customerReactSelect}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-4">
                                                <label>Monedas {isLoading?'Cargando':''}</label>
                                                <Select 
                                                    options={currencies}                         
                                                    className="form-control select-styling" 
                                                    value={currencySelected}
                                                    defaultValue={currencySelected} 
                                                    onChange={setCurrencySelected}
                                                    ref={currencyReactSelect}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col">
                                                <Form.Group className="mb-3" controlId="generalForm.work">
                                                    <Form.Label>Obra</Form.Label>
                                                    <Form.Control type="text" placeholder="Numero de la obra" onChange={(input)=>{
                                                        setBkParameters({
                                                            ...bkParameters,
                                                            workId: input.target.value
                                                        })
                                                    }} />
                                                </Form.Group>
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>

                                <div className="row">                                    

                                    <div className="col table-responsive">
                                        <table className="table" style={{marginBottom: 0}}>
                                            <thead>
                                                <tr>
                                                    {
                                                        workFlows.map(({value,label},index)=>{
                                                            return <th key={`thKey-${value}-${index}`} style={{fontSize: 11}}>{label}</th>
                                                        })
                                                    }
                                                </tr>                                                
                                            </thead>
                                            <tbody>
                                                {
                                                    workFlows.map(({value},index)=>{
                                                        return (
                                                            <td key={`tdKey-${value}-${index}`}>
                                                                <Form.Group className="mb-3" controlId={`workFlow.key.${value}`}>
                                                                    <Form.Control type="text" placeholder="0" onChange={(input)=>{
                                                                        if ( input.target.value )
                                                                        {
                                                                            const split = input.target.id.split(".")
                                                                            const workFlowId = parseInt(split[2])
                                                                            const qtyDays = parseInt(input.target.value)
                                                                            let localWfData = bkParameters.timesOrder !== undefined && bkParameters.timesOrder !== null ? [...bkParameters.timesOrder] : [];
                                                                            let isInArray = false;
                                                                            for ( let c = 0; c < localWfData.length; c++ )
                                                                            {
                                                                                if ( localWfData[c].workFlowId === workFlowId ){
                                                                                    localWfData[c].qtyDays = qtyDays;
                                                                                    isInArray = true;
                                                                                }
                                                                            }
                                                                            if ( !isInArray ){
                                                                                localWfData.push({
                                                                                    workFlowId: workFlowId,
                                                                                    qtyDays: qtyDays
                                                                                })
                                                                                isInArray = false
                                                                            }
                                                                            let twfd = 0;
                                                                            for ( let l = 0; l < localWfData.length; l++ ){
                                                                                twfd += localWfData[l].qtyDays;
                                                                            }
                                                                            setBkParameters({
                                                                                ...bkParameters,
                                                                                timesOrder: localWfData
                                                                            })
                                                                            setTotalWorkFlowDays(twfd)
                                                                        }
                                                                    }} />
                                                                </Form.Group>
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                                <div className="row mb-3">
                                    <div className="col-4">
                                        <label>Total Días: <span className="bold">{totalWorkFlowDays}</span></label>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-4">
                                        <label>Entrega</label><br />
                                        <DateTimePicker
                                            onChange={setDeliveryDate}
                                            value={deliveryDate}
                                            format="dd-MM-y"
                                            disableClock={true}
                                            minDate={new Date()}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-4">
                                        <label>Fecha orden de proveedor</label><br />
                                        <DateTimePicker
                                            onChange={setSupplierOrderDate}
                                            value={supplierOrderDate}
                                            format="dd-MM-y"
                                            disableClock={true}
                                            minDate={new Date()}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="col">

                                <div className="row">
                                    <div className="col">
                                        <h2 style={{marginTop: 10}}>Listado de Artículos</h2>
                                    </div>
                                </div>                                
                                
                                <div className="row mb-3">
                                    
                                    <SelectGroup
                                        onChange={(input)=>{
                                            const value = parseInt(input.target.value);
                                            if ( value ){
                                                getMaterialsBySubCategory(value)
                                            }                                                
                                        }} 
                                        value={subCategory}
                                        className="col-4" 
                                        inputClassName="form-control"
                                        label="Sub-Categoría"
                                        inputName="subCategory" 
                                        id="subCategory"
                                        options={subCategories}
                                    />
                                    <div className="col-8">
                                        <label style={{marginBottom: '8px'}}>
                                            Artículo
                                            <Button 
                                                type="button"
                                                className="btn-link btn btn-sm"
                                                title="Actualizar lista de artículos"
                                                styleInput={{padding: 0, paddingLeft: 5}}
                                                onClickInput={()=>{
                                                    if( subCategory ) 
                                                    {
                                                        getMaterialsBySubCategory( subCategory );
                                                    }
                                                }}
                                                contentButton={<FontAwesomeIcon icon={faRefresh} style={{fontSize: '14', color: '#000'}} />}
                                            />
                                        </label>
                                        <Select 
                                            options={itemList}                         
                                            className="form-control select-styling" 
                                            value={itemSelected}
                                            defaultValue={itemSelected} 
                                            onChange={setItemSelected}
                                            ref={itemReactSelect}
                                        />
                                    </div>

                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <Form.Group className="mb-3" controlId="itemRow.quantity">
                                            <Form.Label>Cantidad</Form.Label>
                                            <Form.Control type="number" placeholder="0" value={itemQuantitySelected} onChange={(input)=>{
                                                setItemQuantitySelected(input.target.value);
                                            }} />
                                        </Form.Group>
                                    </div>
                                    <div className="col">
                                        <label style={{marginBottom: '8px'}}>
                                            Unidad
                                        </label>
                                        <Select 
                                            options={units}                         
                                            className="form-control select-styling" 
                                            value={unitSelected}
                                            defaultValue={unitSelected} 
                                            onChange={setUnitSelected}
                                            ref={unitReactSelect}
                                        />
                                    </div>
                                    <div className="col">
                                        <Form.Group className="mb-3" controlId="itemRow.price">
                                            <Form.Label>Precio</Form.Label>
                                            <Form.Control type="number" placeholder="0" value={itemPriceSelected} onChange={(input)=>{
                                                setItemPriceSelected(input.target.value);
                                            }} />
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <label style={{marginBottom: '8px'}}>
                                            Impuesto
                                        </label>
                                        <Multiselect
                                            displayValue="key"
                                            // onKeyPressFn={function noRefCheck(){}}
                                            onRemove={(multiSelectInputValues)=>setItemTaxesSelected(multiSelectInputValues)}
                                            // onSearch={function noRefCheck(){}}
                                            onSelect={(multiSelectInputValues)=>setItemTaxesSelected(multiSelectInputValues)}
                                            options={taxes}
                                            ref={multiSelectReactElement}
                                            showCheckbox
                                        />
                                    </div>
                                    <div className="col">
                                        <Form.Group className="mb-3" controlId="itemRow.discount">
                                            <Form.Label>Descuento</Form.Label>
                                            <Form.Control type="number" placeholder="0" value={itemDiscountSelected} onChange={(input)=>{
                                                setItemDiscountSelected(input.target.value);
                                            }} />
                                        </Form.Group>
                                    </div>
                                    <div className="col">
                                        <br />
                                        <Button variant="success" onClick={addItemRow}>
                                            Agregar
                                        </Button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col table-responsive">
                                        <table className="table" style={{marginBottom: 0}}>
                                            <thead>
                                                <tr>
                                                    <th style={{fontSize: 11}}>Opc.</th>
                                                    <th style={{fontSize: 11}}>Producto</th>
                                                    <th style={{fontSize: 11}}>Cantidad</th>
                                                    <th style={{fontSize: 11}}>Precio</th>
                                                    <th style={{fontSize: 11}}>Imp.</th>
                                                    <th style={{fontSize: 11}}>Desc.</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    bkParameters.details.length?
                                                        bkParameters.details.map( ( {
                                                            productId,
                                                            item,
                                                            qty,
                                                            productPrice,
                                                            taxAmount,
                                                            discountAmount
                                                        }, idx ) => {
                                                            return (
                                                                <tr key={`key-prd-${productId}-${idx}`}>
                                                                    <td>
                                                                        <FontAwesomeIcon 
                                                                            icon={faTimes} 
                                                                            style={{fontSize: '18', color: '#f00',cursor: 'pointer'}}
                                                                            onClick={()=>{
                                                                                let localBkP = [...bkParameters.details]
                                                                                localBkP.splice(idx,1);
                                                                                setBkParameters({
                                                                                    ...bkParameters,
                                                                                    details: localBkP
                                                                                })
                                                                            }}
                                                                             />
                                                                    </td>
                                                                    <td>{item}</td>
                                                                    <td>{qty}</td>
                                                                    <td>{productPrice}</td>
                                                                    <td>{taxAmount}</td>
                                                                    <td>{discountAmount}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    :null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                            </div>

                        </div>

                        <div className="row">

                            <div className="col">

                                <Button variant="success" onClick={()=>{
                                    Swal.fire({
                                        icon: 'warning',
                                        title: 'Confirmación',
                                        text: '¿Esta seguro de procesar esta oportunidad?',
                                        // showDenyButton: true,
                                        showCancelButton: true,
                                        confirmButtonText: 'Procesar',
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        denyButtonText: `Cancelar`
                                    }).then(async (result)=>{
                                        if (result.isConfirmed)
                                            createLead()
                                    })
                                }}>
                                    Crear Oportunidad
                                </Button>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
        <LoadingOverlay isLoading={isLoading} />
    </>
    );
}

defaultParameters.propTypes = {
    customerId: PropTypes.number.isRequired,
    idLeadSteps: PropTypes.number.isRequired,
    currencyId: PropTypes.number.isRequired,
    workId: PropTypes.string.isRequired,
    deliveryDate: PropTypes.string.isRequired,
    supplierDate: PropTypes.string.isRequired,
    leadStatus: PropTypes.number.isRequired,
    timesOrder: PropTypes.array.isRequired,
    details: PropTypes.array.isRequired,
}

export default LeadSale;