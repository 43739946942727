import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { Form, Button } from "react-bootstrap";
import { getDateFormatted } from "../../../Vendor/common";
import LoadingOverlay from "../../../Layouts/NiceAdmin/LoadingOverlay";

const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
};
const Design = () => {
    const columns = [    
        {
            name: 'OPC.',
            selector: row => (
                <Form.Check
                    type="switch"
                    className="switch-table"
                    id={row.id}
                    label=""
                    onChange={(input)=>{
                        // const selected = input.target.checked;
                        const ID = parseInt( input.target.getAttribute('id') )
                        let localLeadSelected = leadSelected !== undefined && leadSelected !== null ? [...leadSelected] : []
                        if ( !localLeadSelected.some( item => item === ID ) )
                        {
                            localLeadSelected.push(ID)
                        }
                        else
                        {
                            const index = localLeadSelected.indexOf(ID);
                            localLeadSelected.splice(index, 1);
                        }
                        setLeadSelected(localLeadSelected);

                    }}
                />
            ),
            center: true
        },
        {
            name: 'CLIENTE',
            selector: row => row.customer_name,
            center: true
        },
        {
            name: 'OBRA',
            selector: row => row.work_id,
            center: true
        },
        {
            name: 'DÍAS',
            selector: row => row.work_days,
            center: true
        },    
        // {
        //     name: 'MONTO',
        //     selector: row => nFormat(row.amount.toFixed(2)),
        //     sortable: true,
        //     center: true
        // },
        {
            name: 'FECHA DE ENVÍO',
            selector: row => getDateFormatted(row.delivery_date),
            sortable: true,
            center: true
        },
        {
            name: 'FECHA DE ORDEN',
            selector: row => getDateFormatted(row.supplier_date),
            sortable: true,
            center: true
        },
        // {
        //     name: 'DESCRIPCIÓN',
        //     selector: row => row.description,
        //     sortable: true,
        //     center: true
        // }
    ];
    const [firstTimeControlNavigation,setFirstTimeControlNavigation] = useState(true)
    const [loadingTable,setLoadingTable] = useState(false)
    const [docResources,setDocResources] = useState([])
    const [dataTableData,setDataTableData] = useState([])
    const [leadSelected,setLeadSelected] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const localStorageWT = localStorage.getItem('OSHEN-LOC-STR-WT');
    const navigate = useNavigate();
    const changeLeadStatus = async () => {
        setIsLoading(true)
        try {
            const resource = await axios.post(`${process.env.REACT_APP_NV_API_PATH}/v1.0/lead/change/next/step`,
                {
                    leads: leadSelected,
                    nextStep: 2
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorageWT}`
                    }
                }
            ),
            dataSource = resource.data;
            console.log('dataSource::',dataSource);
            location.reload()
        } catch ( err ) {
            setIsLoading(false)
            console.log( '::::ERR::::', err.response);
            Swal.fire({
                icon: "warning",
                title: "Advertencia",
                text: err.response.data.message
            })
        }
    }
    const searchLeadOpenned = async() => {
        setLoadingTable(true);
        try{
            const resource = await axios.get(`${process.env.REACT_APP_NV_API_PATH}/v1.0/lead/resource/openned`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorageWT}`
                    }
                }
            ),
            dataSource = resource.data;
            setDocResources(dataSource.data);
            setLoadingTable(false);
        } catch( error ) {
            console.log('err', error);
            if ( error.response.status == 401 )
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Unauthorized',
                    text: "Token invalido"
                }).then(()=>{
                    location.href = process.env.REACT_APP_PATH_APP;
                });
                return;
            }
            setLoadingTable(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    useEffect(()=>{
        setFirstTimeControlNavigation(false);
        searchLeadOpenned();
    }, []);
    useEffect(()=>{
        if ( !firstTimeControlNavigation ){
            searchLeadOpenned();
        }
    }, [navigate]);
    useEffect(()=>{
        let docData = [];
        if ( docResources )
        {                
            for(let i=0;i<docResources.length;i++) 
            {
                const objData = docResources[i];

                let payload = {

                    id: objData.id,
                    customer_name: objData.customer_name,
                    work_id: objData.work_id,
                    work_days: objData.work_days,
                    delivery_date: objData.delivery_date,
                    supplier_date: objData.supplier_date,
                    description: objData.description
                };

                docData.push(payload);
            }                
        }
        setDataTableData(docData);
    },[docResources]);
    return (
    <>
        <div className='row'>
            <div className='col-12 table-responsive'>
                <DataTable 
                    columns={columns}
                    data={dataTableData}
                    // title="Listado de productos"
                    pagination
                    paginationComponentOptions={paginationOptions}
                    fixedHeader
                    fixedHeaderScrollHeight='600'
                    progressPending={loadingTable}
                />
            </div>
        </div>
        {
            docResources.length?
                <div className="row">

                    <div className="col">

                        <Button variant="success" onClick={()=>{
                            Swal.fire({
                                icon: 'warning',
                                title: 'Confirmación',
                                text: '¿Esta seguro de procesar estas oportunidades?',
                                // showDenyButton: true,
                                showCancelButton: true,
                                confirmButtonText: 'Procesar',
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                denyButtonText: `Cancelar`
                            }).then(async (result)=>{
                                if (result.isConfirmed)
                                    changeLeadStatus()
                            })                    
                        }}>
                            Continuar a Diseño & Cotización
                        </Button>

                    </div>

                </div>
            :null
        }
        <LoadingOverlay isLoading={isLoading} />
    </>
    )
}

export default Design;