import React from "react";
import PropTypes from "prop-types";

const PageTitle = ({ title, navLi }) => {
    return (
        <>
            <div className="pagetitle">
                <h1>{title}</h1>
                <nav>
                    <ol className="breadcrumb">
                        {navLi.map(({active, label, key})=>{                        
                            if ( active )
                                return <li key={key} className={`breadcrumb-item ${active}`}>{label}</li>
                            else
                                return <li key={key} className={`breadcrumb-item`}><a href="#">{label}</a></li>

                        })}
                    </ol>
                </nav>
            </div>
        </>
    )
}

PageTitle.propTypes = {
    title: PropTypes.string,
    navLi: PropTypes.array
}

export default PageTitle;