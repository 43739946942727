import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import LoadingOverlay from "../../../Layouts/NiceAdmin/LoadingOverlay";
import { Button, Card, Form, ListGroup, Modal, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faEdit, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import { getDateFormatted } from "../../../Vendor/common";
import DateTimePicker from 'react-datetime-picker';

const defaultUpdatingValues = {
    updating: false,
    statusColor: "secondary",
    status: "NO REPORTADO",
    sla: 0,
    process: "",
    dueDate: "",
    progressBar: "success",
    comments: []
}
const defaultQuoteArr = [{noPayment: 1, percent: 0, leadQuoteDetails: ""}]
const Assign = () => {
    const [updatingValue,setUpdatingValue] = useState(defaultUpdatingValues)
    const [quoteArr,setQuoteArr] = useState(defaultQuoteArr)
    const [dueDate,setDueDate] = useState(new Date())
    const [isLoading,setIsLoading] = useState(false)
    const [leads,setLeads] = useState([])
    const [processes,setProcesses] = useState([])
    const [processLabel,setProcessLabel] = useState("")
    const [modalFire,setModalFire] = useState(false);
    const [quoteModalFire,setQuoteModalFire] = useState(false)
    const [users,setUsers] = useState([])    
    const [userSelected,setUserSelected] = useState(null)
    const [leadProcessId,setLeadProcessId] = useState(null)
    const [leadID,setLeadID] = useState(null)
    const [leadIdSelected,setLeadIdSelected] = useState(null)
    const [assigments,setAssigments] = useState([])
    const [newsComment,setNewsComment] = useState("")
    const localStorageWT = localStorage.getItem('OSHEN-LOC-STR-WT');
    const searchInitData = async() => {
        setIsLoading(true);
        try{
            const resource = await axios.get(`${process.env.REACT_APP_NV_API_PATH}/v1.0/lead/miscellaneous/assigments/lst`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorageWT}`
                    }
                }
            ),
            dataSource = resource.data;
            setLeads(dataSource.leads);
            setProcesses(dataSource.processes);
            setUsers(dataSource.users);
            setAssigments(dataSource.assigmentSla);
            setIsLoading(false)
        } catch( error ) {
            console.log('err', error);
            setIsLoading(false);
            if ( error.response !== undefined && error.response.status == 401 )
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Unauthorized',
                    text: "Token invalido"
                }).then(()=>{
                    location.href = process.env.REACT_APP_PATH_APP;
                });
                return;
            }            
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const closeModal = (modalFire) => {
        setModalFire(modalFire);
        setUpdatingValue(defaultUpdatingValues);
    }
    const closeQuoteModal = (modalFire) => {
        setQuoteModalFire(modalFire);
    }
    const getProcessLabel = (id) => {
        for ( let i = 0; i < processes.length; i++ )
        {
            if ( parseInt(processes[i].id) === parseInt(id) )
                return processes[i].description;
        }
        return "PROCESO NO ENCONTRADO";
    }
    const checkAssigment = (idLead, idProcess) => {
        for ( let i = 0; i < assigments.length; i++ )
        {
            if ( parseInt(assigments[i].id_lead) === parseInt(idLead) && parseInt(assigments[i].id_lead_processes) === parseInt(idProcess) )
            {
                let color = "secondary"
                switch ( assigments[i].id_lead_status ) {
                    case 1:
                        color = "primary"
                        break;
                    case 2:
                        color = "warning"
                        break;
                    case 3:
                        color = "success"
                        break;
                    case 4:
                        color = "danger"
                        break;
                    case 5:
                        color = "info"
                        break;
                }
                let progressBarColor = "success";
                if ( assigments[i].sla > 50 && assigments[i].sla <= 70 )
                {
                    progressBarColor = "info";
                } else if ( assigments[i].sla > 70 && assigments[i].sla <= 90 ) {
                    progressBarColor = "warning";
                } else if ( assigments[i].sla > 90 ) {
                    progressBarColor = "danger";
                }
                return (
                    <Button variant="light" onClick={()=>{
                        setLeadProcessId(idProcess)
                        setLeadID(idLead)
                        setProcessLabel(getProcessLabel(idProcess));
                        setUpdatingValue({
                            updating: true,
                            statusColor: color,
                            status: assigments[i].lead_status,
                            sla: assigments[i].sla,
                            process: assigments[i].lead_processes,
                            dueDate: getDateFormatted(assigments[i].lead_assigment_date),
                            progressBar: progressBarColor,
                            comments: assigments[i].comments
                        })
                        setUserSelected({
                            value: assigments[i].id_user,
                            label: assigments[i].user_name
                        })
                        setModalFire(true)
                    }}>
                        <FontAwesomeIcon icon={faEdit} /> {assigments[i].personal_user_name} <br /> {assigments[i].lead_status} 
                        <ProgressBar variant={progressBarColor} now={assigments[i].sla} />
                    </Button>
                )                
            }
        }
        return <Button variant="link" onClick={()=>{
                    setLeadProcessId(idProcess)
                    setLeadID(idLead)
                    setProcessLabel(getProcessLabel(idProcess))
                    setUpdatingValue(defaultUpdatingValues)
                    setUserSelected(null)
                    setDueDate(new Date())
                    setModalFire(true)
                }}>
                    Asignar <FontAwesomeIcon icon={faPlus} />
                </Button>
    }
    const reportNews = async (status) => {
        if ( userSelected === null ){
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: "Debe seleccionar al menos un usuario"
            });
            return
        }
        if ( newsComment.length < 1 ){
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: "Debe ingresar un comentario"
            });
            return
        }
        setIsLoading(true);
        try{
            let parameter = {
                idLeadAssigment: leadID,
                idLeadStatus: status,
                idUserAssigment: userSelected.value,
                leadAssigmentComment: newsComment,
                leadAssigment: {
                    idLead: leadID,
                    idUser: userSelected.value,
                    idLeadProcesses: leadProcessId,
                    idLeadStatus: status,
                    leadAssigmentDate: dueDate//getDateFormatted(dueDate)
                }
            }
            const resource = await axios.post(`${process.env.REACT_APP_NV_API_PATH}/v1.0/lead/assigment/news`,
                parameter,
                {
                    headers: {
                        Authorization: `Bearer ${localStorageWT}`
                    }
                }
            ),
            dataSource = resource.data;
            setAssigments(dataSource.assigments)
            setUserSelected(null)
            setNewsComment("")
            setModalFire(false)
            setIsLoading(false)
        } catch( error ) {
            console.log('err', error);
            setIsLoading(false);
            if ( error.response !== undefined && error.response.status == 401 )
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Unauthorized',
                    text: "Token invalido"
                }).then(()=>{
                    location.href = process.env.REACT_APP_PATH_APP;
                });
                return;
            }            
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    // const sendToQuotation = async (idLead) => {
    //     setIsLoading(true);
    //     try{
    //         const resource = await axios.post(`${process.env.REACT_APP_NV_API_PATH}/v1.0/lead/change/next/step`,
    //             {
    //                 leads: [idLead],
    //                 nextStep: 3
    //             },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${localStorageWT}`
    //                 }
    //             }
    //         ),
    //         dataSource = resource.data;
    //         console.log('dataSource', dataSource);
    //         location.reload()
    //         // setIsLoading(false)
    //     } catch( error ) {
    //         console.log('err', error);
    //         setIsLoading(false);
    //         if ( error.response !== undefined && error.response.status == 401 )
    //         {
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Unauthorized',
    //                 text: "Token invalido"
    //             }).then(()=>{
    //                 location.href = process.env.REACT_APP_PATH_APP;
    //             });
    //             return;
    //         }            
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error',
    //             text: "Error Interno, favor contacte al administrador"
    //         });
    //     }
    // }
    const disbursement = async () => {
        let sumPercent = 0;
        for ( let counter = 0; counter < quoteArr.length; counter++ )
        {
            const qArr = quoteArr[counter];
            sumPercent += parseFloat(qArr.percent);
        }
        if ( sumPercent < 100 || sumPercent > 100 )
        {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: "La distribución no coincide con el porcentaje bajo el 100%"
            })
            return
        }
        setIsLoading(true);
        try{
            const resource = await axios.post(`${process.env.REACT_APP_NV_API_PATH}/v1.0/lead/quote/distribution/${leadIdSelected}`,
                quoteArr,
                {
                    headers: {
                        Authorization: `Bearer ${localStorageWT}`
                    }
                }
            ),
            dataSource = resource.data;
            console.log('dataSource', dataSource);
            location.reload()
            // setIsLoading(false)
        } catch( error ) {
            console.log('err', error);
            setIsLoading(false);
            if ( error.response !== undefined && error.response.status == 401 )
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Unauthorized',
                    text: "Token invalido"
                }).then(()=>{
                    location.href = process.env.REACT_APP_PATH_APP;
                });
                return;
            }            
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    useEffect(searchInitData,[]);
    return (
    <>
        <div className="row">
            <div className="col table-responsive">
                <table className="table" style={{marginBottom: 0}}>
                    <thead>
                        <tr>
                            <th style={{fontSize: 11}}>Cotizar</th>
                            <th style={{fontSize: 11}}>Obra</th>
                            {
                                processes.map(({id,description})=>{
                                    return <th key={`th-key-assign-${id}`} style={{fontSize: 11}}>{description}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            leads.length?
                                leads.map( ( {id, work_id}, idx ) => {
                                    let lID = id;
                                    return (
                                        <tr key={`key-work-${work_id}-${idx}`}>
                                            <td>
                                                <FontAwesomeIcon 
                                                    icon={faCoins} 
                                                    style={{cursor: 'pointer'}} 
                                                    onClick={()=>{
                                                        setLeadIdSelected(lID)
                                                        setQuoteModalFire(true)
                                                        /*sendToQuotation(lID)*/
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {work_id}
                                            </td>
                                            {
                                                processes.map(({id})=>{
                                                    return <td key={`td-key-assign-${id}`} style={{fontSize: 11}}>{ checkAssigment(lID, id) }</td>
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            :null
                        }
                    </tbody>
                </table>
            </div>
        </div>
        <LoadingOverlay isLoading={isLoading} />
        <Modal 
            size="xl" 
            show={modalFire} 
            onHide={()=>closeModal(!modalFire)} 
            backdrop="static" keyboard={false}
        >
            <Modal.Header className="custom-modal-header" closeButton>
                <Modal.Title>Registro de incidencia</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: 0}}>
                <div className="card">
                    <div className="card-body">
                        <div className="row" style={{paddingTop: 10}}>
                            <div className="col">

                                <div className="row">
                                    <div className="col">
                                        <h4>Proceso: {processLabel}</h4>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <Card bg={updatingValue.statusColor} style={{marginBottom: 5}}>
                                            <Card.Body style={{padding: 10, fontWeight: 'bold'}}>Estatus actual: {updatingValue.status}</Card.Body>
                                        </Card>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <label>Usuario</label>
                                        <Select 
                                            options={users}                         
                                            className="form-control select-styling" 
                                            value={userSelected}
                                            defaultValue={userSelected} 
                                            onChange={setUserSelected}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <Form.Group controlId="form.comment">
                                            <Form.Label>Comentario</Form.Label>
                                            <Form.Control type="text" placeholder="Comentario de la incidencia" onChange={(input)=>setNewsComment(input.target.value)} />
                                        </Form.Group>
                                    </div>
                                </div>

                                {
                                    updatingValue.comments.length?
                                        <div className="row mb-3">
                                            <div className="col">
                                            <ListGroup as="ul">
                                                <ListGroup.Item as="li">
                                                    Comentarios
                                                </ListGroup.Item>
                                                {
                                                    updatingValue.comments.map( ( {usr_name,comments,comment_date},idxComment ) => {
                                                        return <ListGroup.Item key={`key-comment-${idxComment}`} as="li">{comments} <span className="text-muted" style={{fontSize: 11}}>{usr_name}-{comment_date}</span></ListGroup.Item>
                                                    })
                                                }
                                            </ListGroup>
                                            </div>
                                        </div>
                                    :null
                                }

                                {
                                    !updatingValue.updating?
                                        <div className="row mb-3">
                                            <div className="col-4">
                                                <label>Fecha de Vencimiento</label><br />
                                                <DateTimePicker
                                                    onChange={setDueDate}
                                                    value={dueDate}
                                                    format="dd-MM-y"
                                                    disableClock={true}
                                                    minDate={new Date()}
                                                />
                                            </div>
                                        </div>
                                    : null
                                }                                

                            </div>
                            <div className="col" style={{paddingTop: 10}}>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label>SLA ({parseInt(updatingValue.sla)}%) / Vencimiento {updatingValue.dueDate}</label>
                                        <ProgressBar variant={updatingValue.progressBar} now={parseInt(updatingValue.sla)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label>Ajustar estatus del proceso</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Card style={{ width: '18rem' }}>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item style={{padding:0}}>
                                                    <Button variant="primary" style={{width: '100%',borderRadius: 0}} onClick={()=>reportNews(1)}>Registro de incidencia</Button>
                                                </ListGroup.Item>
                                                <ListGroup.Item style={{padding:0}}>
                                                    <Button variant="outline-warning" style={{width: '100%',borderRadius: 0}} onClick={()=>reportNews(2)}>No Iniciado</Button>
                                                </ListGroup.Item>
                                                <ListGroup.Item style={{padding:0}}>
                                                    <Button variant="outline-success" style={{width: '100%',borderRadius: 0}} onClick={()=>reportNews(3)}>Iniciado</Button>
                                                </ListGroup.Item>
                                                <ListGroup.Item style={{padding:0}}>
                                                    <Button variant="outline-danger" style={{width: '100%',borderRadius: 0}} onClick={()=>reportNews(4)}>Detenido</Button>
                                                </ListGroup.Item>
                                                <ListGroup.Item style={{padding:0}}>
                                                    <Button variant="outline-info" style={{width: '100%',borderRadius: 0}} onClick={()=>reportNews(5)}>Terminado</Button>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal.Footer>
                    <Button variant="danger" onClick={()=>setModalFire(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
        <Modal 
            size="lg" 
            show={quoteModalFire} 
            onHide={()=>closeQuoteModal(!quoteModalFire)} 
            backdrop="static" keyboard={false}
        >
            <Modal.Header className="custom-modal-header" closeButton>
                <Modal.Title>Desembolso total en función a unidades contratadas</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: 0}}>
                <div className="card">
                    <div className="card-body">
                        <div className="row" style={{paddingTop: 10}}>
                            <div className="col table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                No. De Pago
                                                <FontAwesomeIcon icon={faMinus} style={{cursor: 'pointer',marginLeft: 10, color: '#f00'}} onClick={()=>{
                                                    let localQuoteArr = [...quoteArr];
                                                    localQuoteArr.splice((localQuoteArr.length-1), 1);
                                                    setQuoteArr(localQuoteArr)
                                                }} />
                                                <FontAwesomeIcon icon={faPlus} style={{cursor: 'pointer',marginLeft: 10, color: '#1a8754'}} onClick={()=>{
                                                    let localQuoteArr = [...quoteArr];
                                                    localQuoteArr.push({
                                                        noPayment: (localQuoteArr.length+1),
                                                        percent: 0,
                                                        leadQuoteDetails: ""
                                                    })
                                                    setQuoteArr(localQuoteArr)
                                                }} />
                                            </th>
                                            <th>%</th>
                                            <th>Detalle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            quoteArr.map(( {noPayment, percent, leadQuoteDetails}, quoteIndex ) => {
                                                return (
                                                    <tr key={`key-row-no${noPayment}`}>
                                                        <td>{noPayment}</td>
                                                        <td>
                                                            <Form.Group controlId={`quoteForm.inputPercent-${noPayment}`}>
                                                                <Form.Control type="number" placeholder="0.00" value={percent} onChange={(input)=>{
                                                                    const inputPercentValue = input.target.value
                                                                    let localQuoteArr = [...quoteArr];
                                                                    localQuoteArr[quoteIndex].percent = inputPercentValue
                                                                    setQuoteArr(localQuoteArr)
                                                                }} />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group controlId={`quoteForm.inputDetail-${noPayment}`}>
                                                                <Form.Control type="text" value={leadQuoteDetails} onChange={(input)=>{
                                                                    const inputDetailValue = input.target.value
                                                                    let localQuoteArr = [...quoteArr];
                                                                    localQuoteArr[quoteIndex].leadQuoteDetails = inputDetailValue
                                                                    setQuoteArr(localQuoteArr)
                                                                }} />
                                                            </Form.Group>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={()=>setQuoteModalFire(false)}>
                    Cerrar
                </Button>
                <Button variant="success" onClick={()=>disbursement()}>
                    Guardar Desembolso
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default Assign;