import React from "react";
export const renderAside = (page) => {
    const feNavLi = [
        {
            key: 'face',
            label: "Facturas electrónicas",
            uri: '#',
            children: [
                {
                    key: 'fcfe',
                    label: "Factura de Crédito Fiscal Electrónica",
                    uri: "/nadmin-invoice-fiscal-transfer"
                },
                {
                    key: 'fce',
                    label: "Factura de Consumo Electrónica",
                    uri: "/nadmin-invoice-transfer"
                },
                {
                    key: 'free',
                    label: "Factura para Regímenes Especiales Electrónico",
                    uri: "/nadmin-invoice-special-regimen-transfer"
                },
                {
                    key: 'fge',
                    label: "Facturas Gubernamentales Electrónicas",
                    uri: "/nadmin-invoice-governmental-transfer"
                },
                {
                    key: 'fee',
                    label: "Factura de Exportación Electrónica",
                    uri: "/nadmin-invoice-export-transfer"
                }
            ]
        },
        {
            key: 'ne',
            label: "Notas electrónicas",
            uri: '#',
            children: [
                {
                    key: 'nde',
                    label: "Nota de Crédito Electrónica",
                    uri: "/nadmin-credit-note-transfer"
                }
            ]
        }            
    ];
    const leadNavLi = [
        {
            key: 'lead',
            label: "Oportunidades",
            uri: '#',
            children: [
                {
                    key: 'sale-lead',
                    label: "Oportunidad de Venta",
                    uri: "/nadmin-lead"
                },
                {
                    key: 'sale-lead-steps',
                    label: "Enviar a diseño",
                    uri: "/nadmin-lead-design"
                },
                {
                    key: 'sale-lead-assign',
                    label: "Assignación & Seguimiento",
                    uri: "/nadmin-lead-assign"
                }
            ]
        }           
    ];
    switch ( page )
    {
        case "invoice-fiscal-transfer":
            return feNavLi
        case "invoice-transfer":
            return feNavLi
        case "invoice-special-regimen-transfer":
            return feNavLi
        case "invoice-governmental-transfer":
            return feNavLi
        case "invoice-export-transfer":
            return feNavLi
        case "credit-note-transfer":
            return feNavLi
        case "lead":
            return leadNavLi
        case "lead-design":
            return leadNavLi
        case "lead-assign":
            return leadNavLi
        default:
            return <p>Menu isnt rendering...</p>
    }
}