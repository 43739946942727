import { faCircleInfo, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import LoadingOverlay from "../../../Layouts/NiceAdmin/LoadingOverlay";
import { getDateFormatted, nFormat } from "../../../Vendor/common";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { Button, Modal } from "react-bootstrap";
import { Constants } from "../../../Vendor/constants";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";

const columns = [
    {
        name: 'ENVIAR',
        selector: row => row.send,
        center: true
    },
    {
        name: 'CONSULTAR',
        selector: row => row.consultData,
        center: true
    },
    {
        name: 'ENCF',
        selector: row => row.encf,
        center: true
    },
    {
        name: 'CLIENTE',
        selector: row => row.rnc+' | '+row.companyName,
        center: true
    },    
    {
        name: 'MONTO',
        selector: row => nFormat(row.amount.toFixed(2)),
        sortable: true,
        center: true
    },
    {
        name: 'STATUS',
        selector: row => row.status,
        sortable: true,
        center: true
    }   
];
const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
};
const InvoiceTransfer = ({ncfTypeCode}) => {
    const [firstTimeControlNavigation,setFirstTimeControlNavigation] = useState(true);
    const [docResources,setDocResources] = useState([]);
    const [resumes,setResumes] = useState(null);
    const [dataTableData,setDataTableData] = useState([]);
    const [loadingTable,setLoadingTable] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [modalFire,setModalFire] = useState(false);
    const [modalInfo,setModalInfo] = useState({
        customer: "",
        rnc: "",
        encf: "",
        docDate: "",
        fe: {
            doc_id: "",
            encf: "",
            security_code: "",
            signature_date: "",
            status: "",
            legal_status: null,
            track_id: null,
            government_response: null
        },
        exemptAmount: 0,
        i1AmountTaxed: 0,
        itbis1Total: 0,
        itbisS1: 0,
        itbisTotal: 0,
        totalAmount: 0,
        totalTaxedAmount: 0,
        items: []
    });
    const [modalInfoDataSource,setModalInfoDataSource] = useState(null);
    const [controlUpdateAction,setControlUpdateAction] = useState(false);

    const [fromRangeDate, setFromRangeDate] = useState(new Date());
    const [toRangeDate, setToRangeDate] = useState(new Date());

    const localStorageWT = localStorage.getItem('OSHEN-LOC-STR-WT');
    const navigate = useNavigate();
    const docGetter = (encf) => {
        for ( let i = 0; i < docResources.length; i++ )
        {
            const e_ncf = docResources[i].idDoc.encf;
            if ( e_ncf == encf )
                return docResources[i];
        }
        return null;
    }
    const getInvoiceSelected = (input) => {
        const ENCF = input.currentTarget.getAttribute('id');
        const docRes = docGetter(ENCF);
        if ( docRes ) {
            setModalInfoDataSource(docRes);
        }
    }
    const sendInvoice = (input) => {
        const ENCF = input.currentTarget.getAttribute('id');
        const docRes = docGetter(ENCF);
        if ( !docRes )
        {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: "No se ha encontrado información de la factura."
            });
            return;
        }        
        if ( ncfTypeCode === 32 && docRes.totals.totalAmount > 250000 )
        {
            Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: "Este tipo de comprobante no puede exceder $250,000.00"
            });
            return;
        }
        Swal.fire({
            icon: 'warning',
            title: 'Confirmación',
            text: '¿Esta seguro de procesar este E-NCF?',
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            denyButtonText: `Cancelar`
        }).then(async (result)=>{
            if (result.isConfirmed) 
            {
                try{
                    setIsLoading(true);
                    const resource = await axios.post(`${process.env.REACT_APP_NV_API_PATH}/sales/doc/${ncfTypeCode}`,
                        docRes,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorageWT}`
                            }
                        }
                    ),
                    data = resource.data;

                    /********** SAVE RESPONSE **********/
                    const splitTimeStamp = data.signatureDate.toString().split(' ');
                    const splitDate = splitTimeStamp[0];
                    const splitDateArr = splitDate.split('-');
                    const dateDay = splitDateArr[0];
                    const dateMonth = splitDateArr[1];
                    const dateYear = splitDateArr[2];
                    const timeStamp = `${dateYear}-${dateMonth}-${dateDay} ${splitTimeStamp[1]}`;
                    const resFeInvoice = await axios.post(`${process.env.REACT_APP_NV_API_PATH}/sales`,{
                        doc_id: data.id,
                        status: data.status,
                        // encf: data.encf,
                        encf: ENCF,
                        signature_date: timeStamp,
                        security_code: data.securityCode,
                        sequence_consumed: data.sequenceConsumed
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorageWT}`
                        }
                    }
                    ),
                    res = resFeInvoice.data;                    
                    console.log('<:res:>', res);
                    location.reload();
                } catch ( err ) {
                    setIsLoading(false);
                    console.log( '::::ERR::::', err.response);
                    Swal.fire({
                        icon: "warning",
                        title: "Advertencia",
                        text: err.response.data.message
                    })                    
                }
            }
        });
    }
    const updateFeInvoice = async (eDocId) => {
        // Swal.fire({
        //     icon: 'warning',
        //     title: 'Confirmación',
        //     text: '¿Esta seguro de actualizar los datos de este E-NCF?',
        //     // showDenyButton: true,
        //     showCancelButton: true,
        //     confirmButtonText: 'Enviar',
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     denyButtonText: `Cancelar`
        // }).then(async (result)=>{
        //     if (result.isConfirmed) 
        //     {
                try{
                    setIsLoading(true);
                    const resource = await axios.post(`${process.env.REACT_APP_NV_API_PATH}/v1.0/sales/update/doc/${eDocId}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${localStorageWT}`
                            }
                        }
                    ),
                    data = resource.data;
                    let governmentRes = "";
                    if ( data.data.governmentResponse !== undefined && data.data.governmentResponse !== null && data.data.governmentResponse.code > 1 )
                        for ( let counter = 0; counter < data.data.governmentResponse.value.length; counter++ )
                        {
                            const grv = data.data.governmentResponse.value[counter];
                            governmentRes += `Code: ${grv.codigo} - Message: ${grv.valor}`
                        }
                    setModalInfo({
                        ...modalInfo,
                        fe: {
                            ...modalInfo.fe,
                            status: data.data.status,
                            legal_status: data.data.legalStatus,
                            government_response: governmentRes
                        }
                    })
                    /********** SAVE RESPONSE **********/
                    // const splitTimeStamp = data.signatureDate.toString().split(' ');
                    // const splitDate = splitTimeStamp[0];
                    // const splitDateArr = splitDate.split('-');
                    // const dateDay = splitDateArr[0];
                    // const dateMonth = splitDateArr[1];
                    // const dateYear = splitDateArr[2];
                    // const timeStamp = `${dateYear}-${dateMonth}-${dateDay} ${splitTimeStamp[1]}`;
                    // const resFeInvoice = await axios.post(`${process.env.REACT_APP_NV_API_PATH}/sales`,{
                    //     doc_id: data.id,
                    //     status: data.status,
                    //     // encf: data.encf,
                    //     encf: ENCF,
                    //     signature_date: timeStamp,
                    //     security_code: data.securityCode,
                    //     sequence_consumed: data.sequenceConsumed
                    // },
                    // {
                    //     headers: {
                    //         Authorization: `Bearer ${localStorageWT}`
                    //     }
                    // }
                    // ),
                    // res = resFeInvoice.data
                    // console.log('<:res:>', res);
                    // location.reload();
                    setControlUpdateAction(true);
                    setIsLoading(false);
                } catch ( err ) {
                    setIsLoading(false);
                    console.log( '::::ERR::::', err);
                    Swal.fire({
                        icon: "warning",
                        title: "Advertencia",
                        text: err.response.data.message
                    })                    
                }
        //     }
        // });
    }
    const searchInvoicesData = async(fromDate, toDate) => {
        setLoadingTable(true);
        try{
            let extraParameters = '';

            if ( ( fromDate !== undefined && fromDate !== null ) && ( toDate !== undefined && toDate !== null ) )
            {
                extraParameters = `/${fromDate}/${toDate}`;
            }
           
            const resource = await axios.get(`${process.env.REACT_APP_NV_API_PATH}/sales/${ncfTypeCode}${extraParameters}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorageWT}`
                    }
                }
            ),
            dataSource = resource.data;
            setDocResources(dataSource.data);
            setResumes(dataSource.resumeFe);
            setLoadingTable(false);
        } catch( error ) {
            console.log('err', error);
            if ( error.response.status == 401 )
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Unauthorized',
                    text: "Token invalido"
                }).then(()=>{
                    location.href = process.env.REACT_APP_PATH_APP;
                });
                return;
            }
            setLoadingTable(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    const closeModal = (modalFire) => {
        setModalFire(modalFire);
        setModalInfoDataSource(null);
        if ( controlUpdateAction ) {
            setControlUpdateAction(false);
            searchInvoicesData(); //Hay que buscar la lista ya que se realizó una actualización.
        }
    }
    useEffect(()=>{
        if ( !firstTimeControlNavigation ){
            searchInvoicesData();
        }
    }, [navigate]);
    useEffect(()=>{
        setFirstTimeControlNavigation(false);
        searchInvoicesData();
    }, []);
    useEffect(()=>{
        let docData = [];
        if ( docResources )
        {                
            for(let i=0;i<docResources.length;i++) 
            {
                const objData = docResources[i];
                const docObject = objData.idDoc;
                const docBuyer = objData.buyer;
                const docTotals = objData.totals;
                const feInfoObject = objData.feInfo;
                let feInfo = null;

                if ( feInfoObject )
                {
                    if ( feInfoObject.encf === docObject.encf )
                    {
                        feInfo = feInfoObject;
                    }
                }

                let payload = {
                    encf: docObject.encf,
                    sequenceDueDate: docObject.sequenceDueDate,
                    rnc: docBuyer.rnc,
                    companyName: docBuyer.companyName,
                    itbis: docTotals.itbisTotal,
                    amount: docTotals.totalAmount,
                    send: feInfo === null ?<FontAwesomeIcon 
                            id={docObject.encf} 
                            icon={faPaperPlane} 
                            onClick={sendInvoice} 
                            style={{
                                fontSize: '18px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                color: '#0d6efd'
                            }} 
                        /> : null,
                    consultData: <FontAwesomeIcon 
                                    id={docObject.encf} 
                                    icon={faCircleInfo} 
                                    onClick={getInvoiceSelected} 
                                    style={{
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        color: '#0d6efd'
                                    }} 
                                />,
                    status: "NO PROCESADA"
                };

                if ( feInfo !== null ) {
                    payload.status = feInfo.status;
                }

                docData.push(payload);
            }                
        }
        setDataTableData(docData);
    },[docResources]);
    useEffect(()=>{
        if ( modalInfoDataSource !== null )
        {
            const dF = new Date(modalInfoDataSource.idDoc.sequenceDueDate),
            date = `${dF.getDate()}/${(dF.getMonth()+1)}/${dF.getFullYear()}`;
            const fe = {
                doc_id: "",
                encf: "",
                security_code: "",
                signature_date: "",
                status: ""
            }
            if ( modalInfoDataSource.feInfo ) {                
                fe.doc_id = modalInfoDataSource.feInfo.doc_id;
                fe.encf = modalInfoDataSource.feInfo.encf;
                fe.security_code = modalInfoDataSource.feInfo.security_code;
                fe.legal_status = modalInfoDataSource.feInfo.legal_status;
                fe.track_id = modalInfoDataSource.feInfo.track_id;
                fe.government_response = modalInfoDataSource.feInfo.government_response;
                const sd = new Date(modalInfoDataSource.feInfo.signature_date),
                sdDate = `${sd.getDate()}/${(sd.getMonth()+1)}/${sd.getFullYear()}`;
                fe.signature_date = sdDate;
                fe.status = modalInfoDataSource.feInfo.status;
            }
            setModalInfo({
                customer: modalInfoDataSource.buyer.companyName,
                rnc: modalInfoDataSource.buyer.rnc,
                encf: modalInfoDataSource.idDoc.encf,
                docDate: date,
                fe: fe,
                exemptAmount: modalInfoDataSource.totals.exemptAmount,
                i1AmountTaxed: modalInfoDataSource.totals.i1AmountTaxed,
                itbis1Total: modalInfoDataSource.totals.itbis1Total,
                itbisS1: modalInfoDataSource.totals.itbisS1,
                itbisTotal: modalInfoDataSource.totals.itbisTotal,
                totalAmount: modalInfoDataSource.totals.totalAmount,
                totalTaxedAmount: modalInfoDataSource.totals.totalTaxedAmount,
                items: modalInfoDataSource.itemDetails
            });
            setModalFire(true);
        }
    },[modalInfoDataSource])
    return (
        <>
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <h6 className="card-title" style={{paddingTop: 10, paddingBottom: 0, marginBottom: 0}}>Funcionalidades no habilitadas en esta versión</h6>
                        <ul>
                            <li>Aplicación de Rentenciones</li>
                            <li>Transferir documentos con moneda diferente a Pesos Dominicanos</li>
                        </ul>
                        <div className='row' style={{marginBottom: 15}}>
                            <div className='col-6'>
                                <label>Fecha Desde</label>
                                <DateTimePicker
                                    onChange={setFromRangeDate}
                                    value={fromRangeDate}
                                    format="dd-MM-y"
                                    disableClock={true}
                                    // minDate={new Date()}
                                />
                            </div>
                            <div className='col-6'>
                                <label>Fecha Hasta</label>
                                <DateTimePicker
                                    onChange={setToRangeDate}
                                    value={toRangeDate}
                                    format="dd-MM-y"
                                    disableClock={true}
                                    // minDate={new Date()}
                                />
                            </div>
                        </div>

                        <div className='row' style={{marginBottom: 15}}>
                            <div className='col-6'>
                                <Button 
                                    variant="primary" 
                                    onClick={()=>{
                                        const from = getDateFormatted(fromRangeDate);
                                        const to = getDateFormatted(toRangeDate);
                                        searchInvoicesData(from,to);
                                    }}
                                >Consultar Datos</Button>
                                <Button 
                                    variant="default" 
                                    onClick={()=>searchInvoicesData()}
                                >Limpiar Busqueda</Button>
                            </div>
                        </div>

                        {
                            resumes!==undefined&&resumes!==null?
                            <>
                                <div className='row'>
                                    <div className='col-3'>
                                        <div className="card text-bg-light mb-3">
                                            <div className="card-header">No Procesado</div>
                                            <div className="card-body">
                                                <h3 className="card-title" style={{fontSize: 45, textAlign: 'center'}}>{nFormat(parseInt(resumes.noProcess))}</h3>
                                                <p className="card-text">Facturas a la espera de ser transferidas</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="card text-bg-light mb-3">
                                            <div className="card-header">Registrado</div>
                                            <div className="card-body">
                                                <h3 className="card-title" style={{fontSize: 45, textAlign: 'center'}}>{nFormat(parseInt(resumes.registered))}</h3>
                                                <p className="card-text">Facturas ha sido transferida y registrada en la DGII</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="card text-bg-light mb-3">
                                            <div className="card-header">En Proceso</div>
                                            <div className="card-body">
                                                <h3 className="card-title" style={{fontSize: 45, textAlign: 'center'}}>{nFormat(parseInt(resumes.inProcess))}</h3>
                                                <p className="card-text">Facturas siendo procesadas por la DGII</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="card text-bg-light mb-3">
                                            <div className="card-header">Aprobadas</div>
                                            <div className="card-body">
                                                <h3 className="card-title" style={{fontSize: 45, textAlign: 'center'}}>{nFormat(parseInt(resumes.approved))}</h3>
                                                <p className="card-text">Facturas aprobadas por la DGII</p>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                                <div className='row'>
                                    <div className='col-3'>
                                        <div className="card text-bg-light mb-3">
                                            <div className="card-header">Rechazadas</div>
                                            <div className="card-body">
                                                <h3 className="card-title" style={{fontSize: 45, textAlign: 'center'}}>{nFormat(parseInt(resumes.rejected))}</h3>
                                                <p className="card-text">Facturas rechazadas por la DGII</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="card text-bg-light mb-3">
                                            <div className="card-header">Observación</div>
                                            <div className="card-body">
                                                <h3 className="card-title" style={{fontSize: 45, textAlign: 'center'}}>{nFormat(parseInt(resumes.observation))}</h3>
                                                <p className="card-text">Facturas con observaciones de la DGII</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className="card text-bg-light mb-3">
                                            <div className="card-header">Total</div>
                                            <div className="card-body">
                                                <h3 className="card-title" style={{fontSize: 45, textAlign: 'center'}}>{nFormat(parseInt(resumes.total))}</h3>
                                                <p className="card-text">Resumen de Dashboard</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :null
                        }

                        <div className='row'>
                            <div className='col-12 table-responsive'>
                                <DataTable 
                                    columns={columns}
                                    data={dataTableData}
                                    // title="Listado de productos"
                                    pagination
                                    paginationComponentOptions={paginationOptions}
                                    fixedHeader
                                    fixedHeaderScrollHeight='600'
                                    progressPending={loadingTable}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <LoadingOverlay isLoading={isLoading} />
        <Modal 
            size="xl" 
            show={modalFire} 
            onHide={()=>closeModal(!modalFire)} 
            backdrop="static" keyboard={false}
        >
            <Modal.Header className="custom-modal-header" closeButton>
                <Modal.Title>Información De Documento</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: 0}}>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th colSpan={2} scope="col">Datos de Factura</th>
                                        </tr>
                                        <tr>
                                            <th scope="col">RNC</th>
                                            <td>{modalInfo.rnc}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Cliente</th>
                                            <td>{modalInfo.customer}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">E-NCF</th>
                                            <td>{modalInfo.encf}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Fecha</th>
                                            <td>{modalInfo.docDate}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Sub-Total</th>
                                            <td className="text-bold">{nFormat(modalInfo.i1AmountTaxed.toFixed(2))}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">ITBIS</th>
                                            <td className="text-bold">{nFormat(modalInfo.itbisTotal.toFixed(2))}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Total</th>
                                            <td className="text-bold">{nFormat(modalInfo.totalAmount.toFixed(2))}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col">
                                <table className="table table-dark">
                                    <tbody>
                                        <tr>
                                            <th colSpan={2} scope="col">Datos Electrónicos</th>
                                        </tr>
                                        <tr>
                                            <th scope="col">DOCUMENTO</th>
                                            <td>{modalInfo.fe.doc_id}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">ESTATUS LEGAL</th>
                                            <td>{Constants[modalInfo.fe.legal_status]}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">FECHA DE FIRMA</th>
                                            <td>{modalInfo.fe.signature_date}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">ESTADO</th>
                                            <td>{Constants[modalInfo.fe.status]}</td>
                                        </tr>
                                        {
                                            modalInfo.fe.government_response!==undefined&&modalInfo.fe.government_response!==null&&modalInfo.fe.government_response.length?
                                            <tr>
                                                <th scope="col">RESPUESTA DGII</th>
                                                <td>{modalInfo.fe.government_response}</td>
                                            </tr>
                                            :null
                                        }
                                    </tbody>
                                </table>
                                {
                                    modalInfo.fe.status !== 'FINISHED' && modalInfo.fe.status.length?
                                        <div className="row">
                                            <div className="col">
                                                <Button variant="primary" onClick={()=>updateFeInvoice(modalInfo.fe.doc_id)}>Actualizar Datos Electrónicos</Button>
                                            </div>
                                        </div>
                                    :null
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {
                                    modalInfo.items.length?
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Artículo</th>
                                                    <th scope="col">Cantidad</th>
                                                    <th scope="col">Monto</th>                                                    
                                                </tr>
                                            </thead>
                                            <tbody>                                            
                                                {
                                                    modalInfo.items.map(({
                                                        lineNumber,
                                                        itemName,
                                                        itemAmount,
                                                        quantityItem
                                                    })=>{
                                                        return (
                                                            <tr key={`modal-key-item-${lineNumber}`}>
                                                                <th scope="col" style={{textAlign: 'center'}}>{lineNumber}</th>
                                                                <td>{itemName}</td>
                                                                <td>{quantityItem}</td>
                                                                <td>{nFormat(itemAmount.toFixed(2))}</td>                                                                
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    :null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    )
}

InvoiceTransfer.propTypes = {
    ncfTypeCode: PropTypes.number
}

export default InvoiceTransfer;