import { faHome, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
const pn = localStorage.getItem('OSHEN-LOC-STR-PN');
const usrn = localStorage.getItem('OSHEN-LOC-STR-USER');
const Header = () => {
    return (
        <>
        <nav className="navbar navbar-expand-lg bg-light cus-nav">
            <div className="container-fluid">
                <a className="navbar-brand txt-white" href={process.env.REACT_APP_PATH_APP}>
                    Spider Platform
                </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                <div className="collapse navbar-collapse" id="navbarText">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <a className="nav-link active txt-white" aria-current="page" href={process.env.REACT_APP_PATH_APP}><FontAwesomeIcon icon={faHome} /> Inicio</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link txt-white" href="#" onClick={(e)=>{e.preventDefault();location.reload();}}><FontAwesomeIcon icon={faRefresh} /> Refrescar interfaz</a>
                    </li>                    
                </ul>
                <span className="navbar-text txt-white">
                    Hola, {pn} ({usrn})
                </span>
                </div>
            </div>
        </nav>
        </>
    )
}
export default Header;