import React from "react";
import InvoiceTransfer from "./fe/invoice-transfer";
import CreditNote from "./fe/credit-note";
import LeadSale from "./lead/sales";
import Design from "./lead/design";
import Assign from "./lead/assign";

export const renderApp = (page) => {
    switch ( page )
    {
        case "invoice-fiscal-transfer":
            return <InvoiceTransfer ncfTypeCode={31} />
        case "invoice-transfer":
            return <InvoiceTransfer ncfTypeCode={32} />
        case "invoice-special-regimen-transfer":
            return <InvoiceTransfer ncfTypeCode={44} />
        case "invoice-governmental-transfer":
            return <InvoiceTransfer ncfTypeCode={45} />
        case "invoice-export-transfer":
            return <InvoiceTransfer ncfTypeCode={46} />
        case "credit-note-transfer":
            return <CreditNote ncfTypeCode={46} />
        case "lead":
            return <LeadSale />
        case "lead-design":
            return <Design />
        case "lead-assign":
            return <Assign />
        default:
            return <p>Page isnt rendering...</p>
    }
}