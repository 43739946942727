import React from "react";

const Header = () => {
    return (
        <header id="header" className="header fixed-top d-flex align-items-center">

            <div className="d-flex align-items-center justify-content-between">
                <a href={process.env.REACT_APP_PATH_APP} className="logo d-flex align-items-center">
                    <img src="assets/img/logo.png" alt="" />
                    <span className="d-none d-lg-block">NV Portal Spider</span>
                </a>
            </div>

             <nav className="header-nav ms-auto">
                <label><span style={{fontWeight: "bold"}}>Versión: BETA</span>&nbsp;&nbsp;</label>            
            </nav>

        </header>

    )
}

export default Header;