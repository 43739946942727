export const nFormat = (nStr) => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

export const getDateFormatted = (timstamp) => {
    const fecha = new Date(timstamp);

    // Obtener año, mes y día
    const year = fecha.getUTCFullYear();
    const month = String(fecha.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() devuelve 0-11
    const day = String(fecha.getUTCDate()).padStart(2, '0');
    
    // Formatear en YYYY-MM-DD
    const fechaFormateada = `${year}-${month}-${day}`;
    
    return fechaFormateada;
}