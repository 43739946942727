import React from "react";
import { useParams } from "react-router";
// import InvoiceTransfer from "../../apps/fe/invoice-transfer";
import Header from "./header";
import PageTitle from "./pageTitle";
import Aside from "./aside";
// import CreditNote from "../../apps/fe/credit-note";
import { renderApp } from "../../apps/render-app";
import { renderAside } from "../../apps/render-aside";

const Container = () => {
    const { page } = useParams();
    const renderPage = ( page ) => {
        return renderApp( page );
    }
    const asideList = ( page ) => {
        return renderAside(page);
    }
    const pageTitle = ( page ) => {
        let title = "";
        switch ( page )
        {
            case "invoice-fiscal-transfer":
                title = "Factura de Crédito Fiscal Electrónica";
                break
            case "invoice-transfer":
                title = "Factura de Consumo Electrónica";
                break;
            case "invoice-special-regimen-transfer":
                title = "Factura para Regímenes Especiales Electrónico";
                break
            case "invoice-governmental-transfer":
                title = "Facturas Gubernamentales Electrónicas";
                break
            case "invoice-export-transfer":
                title = "Factura de Exportación Electrónica";
                break
            case "lead":
                title = "Oportunidad de Venta";
                break
            case "lead-design":
                title = "Enviar oportunidad a diseño";
                break
            case "lead-assign":
                title = "Asignación & Seguimiento";
                break
            default:
                title = "";
                break
        } 
        return (
            <PageTitle 
                title={title}
                navLi={[
                    {
                        key: "li-key-1",
                        label: "Inicio"
                    },
                    {
                        key: "li-key-2",
                        label: title,
                        active: true
                    }
                ]}
            />
        )
    }
    return (
        <>
            <Header />

            {/* Miss aside */}
            <Aside navLi={asideList(page)} />

            <main id="main" className="main">

                {pageTitle(page)}

                <section className="section">
                    {renderPage(page)}
                </section>

            </main>
        </>
    )
}

export default Container;