import React from "react";
import "../../LoadingOverlay.css"; // Importamos los estilos
import PropTypes from "prop-types";

const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) return null; // No renderiza nada si isLoading es falso

  return (
    <div className="overlay">
      <div className="spinner"></div>
      <p>Cargando...</p>
    </div>
  );
};

LoadingOverlay.propTypes = {
  isLoading: PropTypes.bool
}

export default LoadingOverlay;