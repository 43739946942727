import React, {useEffect,useState,useRef} from "react";
import { faEdit, faList, faPlus, faPlusCircle, faPrint, /*faRefresh*/ } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "react-data-table-component";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import ModalProducts from "../../modal-products";
// import SearchProductInput from "../../atoms/SearchProductInput";
import SearchFinishedProductInput from "../../atoms/SearchFinishedProductInput";
import Header from "../../../Layouts/header";
const columns = [
    /*{
        name: 'ACTUALIZAR PRODUCTO',
        selector: row => row.updateProduct,
        center: true
    },*/
    {
        name: 'RECETA',
        selector: row => row.chooseIcon,
        center: true
    },
    {
        name: 'IMPRIMIR',
        selector: row => row.viewPrint,
        center: true
    },    
    {
        name: 'CÓDIGO',
        selector: row => row.product_code,
        sortable: true,
        center: true
    },
    {
        name: 'PRODUCTO',
        selector: row => row.product_des,
        sortable: true,
        center: true
    },
    {
        name: 'CATEGORÍA',
        selector: row => row.sub_category_des,
        sortable: true,
        center: true
    },
    {
        name: 'PRECIO',
        selector: row => row.total_price_formated,
        right: true
    },
    {
        name: 'INVENTARIO',
        selector: row => row.use_inventory_label,
        center: true
    }    
];
const paginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos'
};
const RecipesView = () => {
    const [loadingTable,setLoadingTable] = useState(false),
        [dataTableData,setDataTableData] = useState([]),

        /** Product Modal */
        [modalProducts,setModalProducts] = useState(false),
        [modalProductsParam,setModalProductsParam] = useState({}),
        [products,setProducts] = useState([]),
        [defaultCurrency,setDefaultCurrency] = useState(0),
        [defaultCurrencyLabel,setDefaultCurrencyLabel] = useState(''),
        [productForRecipeView,setProductForRecipeView] = useState(0),
        [itemProductInfo,setItemProductInfo] = useState(null),

        formRef = useRef(),

        navigate = useNavigate();
    useEffect(()=>{
        searchItemData();
    },[]);
    useEffect(()=>{
        let data = products;
        setIconForData(data);
        setDataTableData(data);
    },[products]);
    useEffect(()=>{
        if(productForRecipeView>0){
            formRef.current.submit();
        }
    },[productForRecipeView]);
    const setIconForData = (data) => {
        for(let i=0;i<data.length;i++){
            data[i].updateProduct = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faEdit} onClick={itemForUpdate} style={{fontSize: '18px',fontWeight: 'bold',cursor: 'pointer',color: '#0d6efd'}} />
            data[i].chooseIcon = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faPlusCircle} onClick={selectItem} style={{fontSize: '18px',fontWeight: 'bold',cursor: 'pointer',color: '#349d12'}} />
            data[i].viewPrint = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faPrint} onClick={viewRecipe} style={{fontSize: '18px',fontWeight: 'bold',cursor: 'pointer',color: '#000000'}} />
        }
        return data;
    }
    const itemForUpdate = async (input) => {
        const id = input.currentTarget.getAttribute('id');
        try{            
            /** CONSULT PRODUCT DATA */
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/product/by/id',{id:id,token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
                data = await resource.data,
            // eslint-disable-next-line react/prop-types
            checkSupplierValues = {},            
            product = {
                id: parseInt(data.product_id),
                code: data.product_code,
                description: data.product_des,
                subCategory: parseInt(data.sub_category_id),
                inventory: data.use_inventory=='t'?true:false,
                isCosting: data.is_costing,
                file: data.path_file,
                pricing: [{
                    id: parseInt(data.objPricing.id),
                    currency: parseInt(data.objPricing.currency_id),
                    price:parseFloat(data.objPricing.total_price),
                    unit:parseInt(data.objPricing.unit_id),
                    supplierValues:checkSupplierValues
                }],
                taxes: data.arrTaxes,
                inventories: [{
                    id: data.objInventory!==null ? parseInt(data.objInventory.id) : 0,
                    location: data.objInventory!==null ? parseInt(data.objInventory.location_id) : 0,
                    qty: data.objInventory!==null ? parseFloat(data.objInventory.product_inventory_qty) : 0,
                    unit: data.objInventory!==null ? parseInt(data.objInventory.unit_id) : 0
                }],
                discounts: {
                    id: data.objDiscount!==null ? parseInt(data.objDiscount.id) : 0,
                    currency: data.objDiscount!==null ? parseInt(data.objDiscount.currency_id) : 0,
                    percent: data.objDiscount!==null ? parseFloat(data.objDiscount.percent) : 0,
                    amount: data.objDiscount!==null ? parseFloat(data.objDiscount.amount) : 0
                }
            };
            setModalProductsParam(data.itemGeneralInformation);
            // eslint-disable-next-line react/prop-types
            setItemProductInfo(product);
            // eslint-disable-next-line react/prop-types
            setModalProducts(true);

        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }        
    }
    const selectItem = async (input) => {
        const id = input.currentTarget.getAttribute('id');
        if(id){
            try{
                // eslint-disable-next-line no-undef
                const resource = await axios.post(process.env.REACT_APP_PATH_API + 'recipe/open', {
                    token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                    productId: id
                }),
                data = resource.data;
                if(!data.status){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Advertencia',
                        text: data.msg
                    });
                    return;
                }
                navigate('/recipes/'+data.product);
            } catch(error){
                Swal .fire({
                    icon: 'error',
                    title: 'Error',
                    text: "Error Interno, favor contacte al administrador"
                });
            }
        }
    }
    const viewRecipe = async (input) => {
        const id = input.currentTarget.getAttribute('id');
        if(id){
            setProductForRecipeView(id);            
        }
    }
    // eslint-disable-next-line no-unused-vars
    const openArticleModel = async () => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/product/info',{token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
                data = await resource.data;
            setModalProductsParam(data);
            setModalProducts(true);
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const searchItemData = async() => {
        setLoadingTable(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'get/datatable/products/finished',{token: localStorage.getItem('OSHEN-LOC-STR-TK')}),
                resourceData = resource.data,
                data = resourceData.dataTable;
            for(let i=0;i<data.length;i++){
                data[i].updateProduct = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faEdit} onClick={itemForUpdate} style={{fontSize: '18px',fontWeight: 'bold',cursor: 'pointer',color: '#0d6efd'}} />
                data[i].chooseIcon = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faList} onClick={selectItem} style={{fontSize: '18px',fontWeight: 'bold',cursor: 'pointer',color: '#349d12'}} />
                data[i].viewPrint = <FontAwesomeIcon id={parseInt(data[i].product_id)} icon={faPrint} onClick={viewRecipe} style={{fontSize: '18px',fontWeight: 'bold',cursor: 'pointer',color: '#000000'}} />
            }
            setDataTableData(data);
            setDefaultCurrency(resourceData.defaultCurrency);
            setDefaultCurrencyLabel(resourceData.defaultCurrencyLabel);
            setLoadingTable(false);
        } catch(error){
            setLoadingTable(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Error Interno, favor contacte al administrador"
            });
        }
    }
    return (
        <>
        <Header />
            <div className='container-fluid' style={{backgroundColor: '#fff'}}>
                <div className="card white-card-border-color">
                    <div className="card-header headers-styling bg-white-color white-card-border-color">                        
                        <div className="row">
                            <div className="col-md-4">
                                {/* <label>
                                <a 
                                    // eslint-disable-next-line no-undef
                                    href={process.env.REACT_APP_PATH_APP}
                                    className="navbar-brand" 
                                    style={{fontSize: '14px'}}
                                    title="Retornar a la aplicación"
                                    >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </a>
                                    Genera tus recetas por producto
                                </label> */}
                            </div>                            
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-md-4'>
                                <SearchFinishedProductInput 
                                    fn={setProducts}
                                    loadingFn={setLoadingTable}
                                />
                            </div>
                            <div className="col-md-8 d-md-flex justify-content-md-end">
                                {/* <button 
                                    type="button" 
                                    className="btn btn-default btn-sm" 
                                    onClick={()=>window.location.reload()}
                                    style={{marginRight: 15}}
                                    title="Refrescar Listado"
                                >
                                    <FontAwesomeIcon icon={faRefresh} />
                                </button> */}
                                <button 
                                    type="button" 
                                    className="btn btn-success btn-md" 
                                    onClick={()=>window.open(process.env.REACT_APP_PATH_APP + 'product?app=10')/*openArticleModel*/}
                                    style={{borderRadius: '20px'}}
                                    title="Nuevo Producto"
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </div>
                        </div>
                        <div className="row mb-3" style={{marginTop: '15px'}}>
                            
                        </div>
                        <div className='row'>
                            <div className='col-12 table-responsive'>
                                <DataTable 
                                    columns={columns}
                                    data={dataTableData}
                                    // title="Listado de productos"
                                    pagination
                                    paginationComponentOptions={paginationOptions}
                                    fixedHeader
                                    fixedHeaderScrollHeight='600'
                                    progressPending={loadingTable}
                                />
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            {/* eslint-disable-next-line no-undef */}
            <form ref={formRef} action={process.env.REACT_APP_PATH_APP+'recipe/view'} method="post" target="_blank"><input type="hidden" id="recipeProductId" name="recipeProductId" value={productForRecipeView} /></form>
            <ModalProducts 
                show={modalProducts} 
                params={modalProductsParam} 
                handle={setModalProducts} 
                handleProducts={setProducts}
                defaultCurrency={defaultCurrency}
                defaultCurrencyLabel={defaultCurrencyLabel} 
                itemProductInfo={itemProductInfo}
                />
        </>
    );
}
export default RecipesView;