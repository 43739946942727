
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const Aside = ({ navLi }) => {
    return (
        <>
            <aside id="sidebar" className="sidebar">
        
                <ul className="sidebar-nav" id="sidebar-nav">

                    {
                        navLi.map(({key,label,uri,children},idx)=>{
                            return (
                                <>
                                {
                                    children!==undefined&&children!==null&&children.length?                                        
                                        (<li key={key+"-"+idx} className="nav-item">
                                            <a className="nav-link" href={`${uri}`}>
                                                <span>{label}</span>
                                            </a>
                                            <ul>
                                            {
                                                children.map(({key,label,uri},idx)=>{
                                                    return <li key={key+"_"+idx} className="nav-item">
                                                                <NavLink className="nav-link" to={uri}>
                                                                    <i className="bi bi-grid"></i>
                                                                    <span>{label}</span>
                                                                </NavLink>
                                                            </li>
                                                })
                                            }
                                            </ul>
                                        </li>)
                                        
                                    :
                                    <li key={key+"_"+idx} className="nav-item">
                                        <NavLink className="nav-link " to={uri}>
                                            <i className="bi bi-grid"></i>
                                            <span>{label}</span>
                                        </NavLink>
                                    </li>
                                }
                                </>                                
                            )
                        })
                    }
            
                </ul>
        
            </aside>
        </>
    )
}

Aside.propTypes = {
    navLi: PropTypes.array
}

export default Aside;